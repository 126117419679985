<template>
  <div class="container">
    <div>
      <van-image
        :src="require('@/assets/images/down_kkai.png')"
        width="2.72rem"
        height="2.72rem"
        class="kkai_logo"
      />
      <p class="kkai-p">KKAI</p>
    </div>
    <ul class="download-contens">
      <li>{{ $t("downloadH5Tips1") }}</li>
      <li>
        <van-image
          :src="require('@/assets/images/taiji.png')"
          width="0.44rem"
          height="0.44rem"
          class="taiji"
        />
        <span>{{ $t("downloadH5Tips2") }}</span>
      </li>
      <li>
        <van-image
          :src="require('@/assets/images/taiji.png')"
          width="0.44rem"
          height="0.44rem"
          class="taiji"
        />
        <span>{{ $t("downloadH5Tips3") }}</span>
      </li>
      <li>
        <van-image
          :src="require('@/assets/images/taiji.png')"
          width="0.44rem"
          height="0.44rem"
          class="taiji"
        />
        <span>{{ $t("downloadH5Tips4") }}</span>
      </li>
      <li>
        <buttons @click.native="handleSubmit" style="margin-top: 0.4rem">
          {{ $t("downloadImmediately") }}
        </buttons>
      </li>
    </ul>
  </div>
</template>
<script>
import Buttons from "@/components/Buttons";
export default {
  name: "downloadH5",
  components: {
    Buttons,
  },
  data() {
    return {};
  },
  methods: {
    handleSubmit() {
      console.log("跳转下载");
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  background: url(../../assets/images/down.png) no-repeat center center;
  background-size: cover;
  font-size: 0.32rem;
  font-weight: 500;
  padding-top: 2.5rem;
}

.kkai_logo {
  display: block;
  margin: 0 auto;
}

.kkai-p {
  text-align: center;
  font-size: 24px;
  color: #a17f3a;
  line-height: 0.66rem;
  margin-bottom: 1.26rem;
}

.download-contens {
  padding: 0 1.36rem;
  > li {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.4rem 0;
  }
}
</style>
